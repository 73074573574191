import $ from 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css';

/*
	Lees routine
 */

export function leesBestand(file) {
	return new Promise(resolve => {
		const fs = new FileReader();
		fs.onload = e => resolve(e.target.result);
		fs.readAsText(file);
	});
}

class ModalImage {
	constructor(images) {
		this.images = images;

		this.modal = $("#modal-image");
		this.content = $(".modal-content", this.modal);
		this.caption = $(".modal-caption", this.modal);
		this.next = $(".next", this.modal);
		this.prev = $(".prev", this.modal);

		this.index = 0;
	}

	show(img) {
		this.modal.show();
		this.content.attr("src", img.src.replace(/-tn(?=\.jpg)/i, ''));
		this.caption.text();

		if (this.images === null) {
			this.next.hide();
			this.prev.hide();
		}
		else {
			this.index = $(img).data("index");
			this.next
				.show()
				.on("mousedown", () => {
					this.index = (this.index + 1) % this.images.length;

					const img = this.images[this.index];
					this.content.attr("src", img.src.replace(/-tn(?=\.jpg)/i, ''));
					this.caption.text($(img).data("desc"));
				});
			this.prev
				.show()
				.on("mousedown", () => {
					this.index = (this.index + this.images.length - 1) % this.images.length;

					const img = this.images[this.index];
					this.content.attr("src", img.src.replace(/-tn(?=\.jpg)/i, ''));
					this.caption.text($(img).data("desc"));
				});
		}
	}
}

/*
	Een plaatjes kijker
*/

class Carousel {

	constructor(div) {
		this.div = div;

		this.slides = [];
		this.thumbNails = [];

		this.slideContainer = $("<div></div>")
			.addClass("slide-container")
			.appendTo(div);

		this.desc = $("<div></div>")
			.addClass("desc")
			.appendTo(div);

		$("<div></div>")
			.addClass("helper")
			.appendTo(this.slideContainer);

		this.ribbon = $("<div></div>")
			.addClass("ribbon")
			.appendTo(div);

		this.strip = $("<div></div>")
			.addClass("strip")
			.appendTo(this.ribbon);
		// Voor het modal image viewer deel

		this.images = $("img", div);
		const modal = new ModalImage(this.images);

		this.images.each((ix, img) => {
			const slide = $("<div></div>")
				.addClass("slides fade")
				.css("display", "none")
				.appendTo(this.slideContainer);

			this.slides.push(slide);
			$("<div/>")
				.addClass("numbertext")
				.text((ix + 1) + " / " + this.images.length)
				.appendTo(slide);

			const src = $(img).attr("src");

			$(img).detach()
				// .attr("src", src.replace(/-tn(?=\.jpg)$/i, '))
				.appendTo(slide)
				.on("mousedown", (e) => modal.show(e.target))
				.data("index", ix);

			// let thumbNail = $(img).attr("tn");
			// if (thumbNail == null) {
			// 	thumbNail = $(img).attr("src").replace(/(\.jpg)$/i, "-tn$1");
			// }

			const tn = $("<img/>")
				.attr("src", src)
				.on("mousedown", () => this.select(ix))
				.appendTo(this.strip);

			this.thumbNails.push(tn);

			const caption = $(img).attr("alt");
			if (caption != null) {
				$("<div></div>")
					.addClass("text")
					.text(caption)
					.appendTo(slide);
			}

		});

		$("<a>&#10094;</a>")
			.addClass("prev")
			.on("mousedown", ()  => this.prev())
			.appendTo(this.slideContainer);

		$("<a>&#10095;</a>")
			.addClass("next")
			.on("mousedown", () => this.next())
			.appendTo(this.slideContainer);
	}

	select(ix) {
		if (ix < 0 || ix >= this.slides.length)
			return;

		for (let i = 0; i < this.slides.length; ++i) {
			const slide = this.slides[i];

			if (i === ix) {
				const img = $("img", slide);
				const src = img.attr("src");
				img.attr("src", src.replace(/-tn(?=\.jpg)/i, ''));
			}

			slide.css("display", i === ix ? "inline-block" : "none");
			$(this.thumbNails[i]).toggleClass("selected", i === ix);
		}

		let left = 0, right = 0;

		$.each($("img", this.strip), (j, img) => {
			if (j === ix) {
				$(img).addClass("selected");
				left = img.x;
				right = left + $(img).width() + 8;
			}
			else
				$(img).removeClass("selected");
		});

		const w = this.strip.width();
		const o = this.strip[0].scrollLeft;

		if (right - o > w) {
			this.strip.stop().animate({scrollLeft: right - w}, 250);
		}
		else if (left - o < 0) {
			this.strip.stop().animate({scrollLeft: left}, 250);
		}

		this.selected = ix;

		// set the desc, if any
		const desc = $(this.images[ix]).data("desc");
		this.desc.text(desc != null ? desc : "");
	}

	next() {
		this.select(this.selected + 1);
	}

	prev() {
		this.select(this.selected - 1);
	}
}

/*	Navigation menu on smaller screens
*/

$(function () {
	$("div.carousel").each(function () {
		const c = new Carousel(this);
		c.select(0);
	});

	// Voor het modal image viewer deel
	const modal = $("#modal-image");
	const content = $(".modal-content", modal);
	const caption = $(".modal-caption", modal);

	$("img.zoomable")
		.on("mousedown", function () {
			modal.show();
			content.attr("src", this.src);
			caption.text(this.alt);
		});

	$(".modal-close", modal)
		.on("mousedown", function () {
			modal.hide();
		});

	$(document).on("keydown", function (e) {
		if (e.keyCode === 27) {
			modal.hide();
		}
	});

	$(document).on("keydown", function (ev) {
		if (Carousel.current != null) {
			if (ev.key === 'ArrowRight') {
				ev.preventDefault();
				Carousel.current.next();
			}
			else if (ev.key === 'ArrowLeft') {
				ev.preventDefault();
				Carousel.current.prev();
			}
		}
	});
});


